/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

import colors from '@solid-ui-theme/color-preset';

export default {
  colors: {
    background: `#FCFCFF`,
    headerBg: `#F6F7FA`,
    footerBg: `#F6F7FA`,
    alphaLighter: colors.blueGray[200],
    alphaLight: colors.blueGray[300],
    alpha: colors.blueGray[700],
    alphaDark: colors.blueGray[800],
    alphaDarker: colors.blueGray[900],
    // beta (secondary)
    betaLighter: colors.lightBlue[100],
    betaLight: colors.lightBlue[300],
    beta: colors.lightBlue[400],
    betaDark: colors.lightBlue[600],
    betaDarker: colors.lightBlue[800]
  }
}
