import React from 'react'
import SVG from 'react-inlinesvg'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

 
const styles = {
  logo: {
    maxWidth: [100, 150],
    maxHeight: `auto`
  }
}

const ImageComponent = ({ image, sx, ...props }) => {
  if (!image?.src) return null
  
  const isSVG = image.src.extension === 'svg'
  if (isSVG) {
    return (
      <SVG
        src={image.src.publicURL}
        css={css(styles.logo)}
      />
    )
  }

  // Image Asset
  const imageData = getImage(image.src)
  if (imageData) {
    return (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
        css={css(styles.logo)}
        {...props}
      />
    )
  }

  return null
}

const CompaniesBlock01 = ({ content }) => {
  const { text, images } = content

  return (
    <Container sx={{ textAlign: `center` }}>
      <ContentText content={text} />
      {text && images && <Divider />}
      <Flex sx={{ flexWrap: `wrap`, m: -3, alignItems: `center`, justifyContent: `center`}}>
        {images?.map((image, index) => (
          <Box key={`item-${index}`} sx={{ flexGrow: 1, p: [1, 3] }}>
            <Reveal
              effect='fadeInGrow'
              delay={0.2 * (index + 2)}
              title={text?.[0]?.text}
            >
              <ImageComponent image={image} />
            </Reveal> 
          </Box>
        ))}
      </Flex>
    </Container>
  );
}

export default WithDefaultContent(CompaniesBlock01)
